import React, { Fragment, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';
import Navbar from 'containers/SaasModern/Navbar';
import BannerSection from '../containers/Custom/Thankyoupage/WebsiteEnrichmentPage';
import Footer from 'containers/AgencyModern/Footer';
import Seo from 'components/seo';
import BlogsLandingPaginated from 'containers/Custom/Thankyoupage/Blogs';
import ResourceLandingWrapper from '../containers/Custom/Thankyoupage/Resources';
import { Helmet } from 'react-helmet';
import Directory from '../containers/Directory';
import Thanksgiving from '../containers/SaasModern/Thanksgiving';
import BookDemoButton from '../containers/SaasModern/ScheduleAdemo/ButtonDemo';
import SignupSuccessDemoVideo from '../containers/Custom/WebsiteEnrichmentThankYouPage/SignupSuccessDemoVideo';
import Header from '../containers/Custom/WebsiteEnrichmentThankYouPage/Header';
import MainComHeading from '../containers/Custom/WebsiteEnrichmentThankYouPage/MainComHeading';

const Thankyoupage = () => {
  const [page, setPage] = useState(1);

  const ThankData = useStaticQuery(graphql`
    {
      allStrapiBlog {
        nodes {
          id
          title
          slug
          date
          metaDescription
          media {
            localFile {
              publicURL
            }
          }
          markdown {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      allStrapiResource {
        nodes {
          id
          title
          slug
          metaDescription
          media {
            localFile {
              publicURL
            }
          }
          markdown {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `);

  const allBlogsData = ThankData.allStrapiBlog.nodes;
  const allResourcesData = ThankData.allStrapiResource.nodes;

  allBlogsData.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Helmet>
          {/* LinkedIn tracking script */}
          <script type="text/javascript">
            {`
              _linkedin_partner_id = "4210812";
              window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
              window._linkedin_data_partner_ids.push(_linkedin_partner_id);
              `}
          </script>
          <script type="text/javascript">
            {`
              (function(l) {
                if (!l) {
                  window.lintrk = function(a, b) {
                    window.lintrk.q.push([a, b]);
                  };
                  window.lintrk.q = [];
                }
                var s = document.getElementsByTagName("script")[0];
                var b = document.createElement("script");
                b.type = "text/javascript";
                b.async = true;
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                s.parentNode.insertBefore(b, s);
              })(window.lintrk);
              `}
          </script>
        </Helmet>
        <Seo
          title="Website Form Enrichment APIs Demo | Boost Leads & Conversions."
          description="Tired of basic website forms? Our AI-powered APIs automatically enrich form data with valuable insights. Thank You for Choosing Clodura.AI"
          robots="noindex, follow"
          canonical="https://www.clodura.ai/thank-you-for-requesting-demo/"
          extraScript={`gtag('event', 'conversion', {'send_to': 'AW-10934282448/LYpPCMWy7IQYENDR790o'});`}
        />
        <ResetCSS />

        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
              {/* <Thanksgiving /> */}
            </DrawerProvider>
          </Sticky>
          <Header />
          <MainComHeading />
          <ResourceLandingWrapper
            resources={allResourcesData.slice(
              (page - 1) * 10,
              (page - 1) * 10 + 10
            )}
          />
          <BlogsLandingPaginated
            blogs={allBlogsData.slice(
              (page - 1) * 18 + 1,
              (page - 1) * 18 + 19
            )}
          />
          <SignupSuccessDemoVideo />
          {/* <Directory /> */}
          <Footer />
          <BookDemoButton />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default Thankyoupage;
